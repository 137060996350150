<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Option to Product</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="assign-form">
          <v-text-field
            label="Price (£) *"
            v-model="fields.price"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>

          <v-text-field
            label="Stock"
            v-model="fields.stock"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('stock')"
            :error-messages="errors['stock']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          :disabled="productLoading"
          form="assign-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      productLoading: false,
      isEditing: false,
      option: {},
      fields: {
        price: null,
        stock: null,
        selectedItem: null,
      },
      errors: {},
    };
  },

  computed: {
    options() {
      return this.$store.getters["eow/productsStore/options"];
    },
  },

  methods: {
    openForm: function (option = null) {
      if (option !== null) {
        this.isEditing = true;

        this.option = option.pivot;

        this.fields.price = option.pivot.price;
        this.fields.stock = option.pivot.stock;

        this.fields.selectedItem = option.id;
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.option = {};
      this.errors = {};

      this.fields.price = null;
      this.fields.stock = null;
      this.fields.selectedItem = null;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.optionId = this.option.id;
      }

      this.$store
        .dispatch("eow/productsStore/saveOptionProduct", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
