<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Related</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="related-form">
          <v-autocomplete
            label="Products"
            v-model="fields.products"
            :items="products"
            item-text="name"
            item-value="id"
            outlined
            multiple
            chips
            deletable-chips
            closable-chips
            counter="3"
            background-color="white"
            :error="errors.hasOwnProperty('products')"
            :error-messages="errors['products']"
          >
            <template #selection="data">
              <draggable
                :id="data.index"
                :list="fields.products"
                v-bind="dragOptionsChips"
                :move="move"
                @change="change"
              >
                <v-chip
                  :closable="true"
                  draggable
                  v-model="fields.products[data.index]"
                  :key="data.item.id"
                  @mousedown.stop
                  @click.stop
                  @click:close="remove(data.item)"
                  close
                >
                  {{ data.item.name }}
                </v-chip>
              </draggable>
            </template>
          </v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          :disabled="productLoading"
          form="related-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      productLoading: false,
      isEditing: false,

      product: {},

      fields: {
        products: [],
      },
      errors: {},

      dragged: {
        from: -1,
        to: -1,
        newIndex: -1,
      },
      selectedSync: [],
    };
  },

  computed: {
    products() {
      return this.$store.getters["eow/productsStore/all"];
    },
    dragOptionsChips() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true,
      };
    },
  },

  watch: {},

  methods: {
    remove(item) {
      this.fields.products.splice(this.fields.products.indexOf(item), 1);
    },

    toggle: function (todo) {
      todo.done = !todo.done;
    },
    move: function (value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },
    change: function (value) {
      if (value.removed) {
        // insert
        this.fields.products.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.fields.products[this.dragged.from]
        );
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.fields.products.splice(this.dragged.from, 1);
        // RTL
        else this.fields.products.splice(this.dragged.from + 1, 1);
      }
    },
    openForm(product = null) {
      if (product !== null) {
        this.isEditing = true;
        this.productLoading = true;

        this.product = product;

        this.fields.products = product.related.map((c) => c.id);
        this.productLoading = false;
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.product = {};
      this.errors = {};

      this.fields.products = [];
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.productId = this.product.id;

      this.$store
        .dispatch("eow/productsStore/saveProductRelated", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;

          this.loading = false;
        });
    },

    thumbnailHasChanged() {
      this.fields.thumbnail_has_changed = true;
    },
  },
};
</script>
