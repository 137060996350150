<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Product</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="detail-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-text-field
            label="Slug *"
            v-model="fields.slug"
            type="text"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>

          <v-select
            label="Category"
            v-model="fields.category_id"
            :items="categories"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('category_id')"
            :error-messages="errors['category_id']"
          ></v-select>

          <v-text-field
            label="Price (£) *"
            v-model="fields.price"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>

          <v-text-field
            label="Stock"
            v-model="fields.stock"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('stock')"
            :error-messages="errors['stock']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          :disabled="productLoading"
          form="detail-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      productLoading: false,
      isEditing: false,
      product: {},
      fields: {
        name: null,
        stock: null,
        category_id: null,
        price: null,
        slug: null,
      },
      errors: {},
    };
  },

  computed: {
    categories() {
      return this.$store.getters["eow/productsStore/categories"];
    },
  },

  watch: {},

  methods: {
    openForm: function (product = null) {
      if (product !== null) {
        this.isEditing = true;
        this.product = product;
        this.fields.isEditing = true;

        this.fields.name = product.name;
        this.fields.price = product.price;

        this.fields.category_id = product.category_id
          ? product.category.id
          : null;
        this.fields.stock = product.stock;
        this.fields.slug = product.slug;
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.product = {};
      this.errors = {};

      this.fields.name = null;
      this.fields.price = null;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.fields.type = "details";
      payload.productId = this.product.id;

      this.$store
        .dispatch("eow/productsStore/saveProduct", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;

          this.loading = false;
        });
    },
  },
};
</script>
