<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-eow-products' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Products</v-btn
        >
      </v-row>
      <v-row no-gutters align="center" class="mt-0">
        <v-col cols="6" align="left">
          <h1>{{ product.name }}</h1>
        </v-col>

        <v-col cols="6" align="right">
          <v-row justify="end" no-gutters>
            <v-select
              label="Status"
              v-model="fields.status"
              :items="statuses"
              :loading="statusLoading"
              outlined
              persistent-hint
              @change="statusChanged()"
            ></v-select>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" class="pr-12">
          <v-card class="mb-5">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Description</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <tiptap
                class="mb-5"
                v-model="fields.description"
                :laravelError="errors.description"
                @error="handleError"
              />
              <v-btn
                :loading="loading"
                align="right"
                color="accent"
                @click="update('description')"
                >Save</v-btn
              >
            </v-card-text>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Size Variations</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.detailDialog.openForm(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
              <v-simple-table class="mb-4">
                <tbody>
                  <tr>
                    <th>Size</th>
                    <th>Price</th>
                    <th>Sale Price</th>
                    <th>Stock Status</th>
                    <th class="text-right">Actions</th>
                  </tr>

                  <tr v-for="option in product.sizes" :key="option.id">
                    <td>{{ option.size.name }}</td>
                    <td>£{{ formatPrice(option.price) }}</td>
                    <td>
                      <div v-if="option.sale_price">
                        £{{ formatPrice(option.sale_price) }}
                      </div>
                    </td>
                    <td>{{ option.stock ? "In Stock" : "Out Of Stock" }}</td>
                    <td class="text-right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            x-small
                            depressed
                            color="blue lighten-4 blue--text"
                            v-on="on"
                            class="mr-2"
                          >
                            <v-icon x-small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            x-small
                            depressed
                            color="red lighten-4 red--text"
                            v-on="on"
                          >
                            <v-icon x-small>mdi-archive</v-icon>
                          </v-btn>
                        </template>
                        <span>Archive</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Size Guide</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.detailDialog.openForm(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Related Colour Options</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.relatedDialog.openForm(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <template v-for="(product, i) in product.related">
                  <v-col v-bind:key="i" cols="12" sm="3" class="text-center">
                    <v-img
                      v-if="product.main_image"
                      height="200"
                      :src="product.main_image.asset_urls.url"
                      aspect-ratio="1"
                      cover
                      class="white--text align-end"
                    >
                    </v-img>
                    <v-img
                      v-else
                      height="200"
                      src="@/assets/placeholder.svg"
                      class="white--text align-end"
                    >
                    </v-img>

                    <v-card-subtitle>{{ product.name }}</v-card-subtitle>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Inventory</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.detailDialog.openForm(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table>
              <tbody>
                <tr>
                  <th>SKU</th>
                  <td>
                    <v-text-field
                      v-model="fields.sku"
                      type="text"
                      outlined
                      dense
                      hide-details
                      background-color="white"
                      :error="errors.hasOwnProperty('sku')"
                      :error-messages="errors['sku']"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <th>GTIN</th>
                  <td>
                    <v-text-field
                      v-model="fields.gtin"
                      type="text"
                      outlined
                      dense
                      hide-details
                      background-color="white"
                      :error="errors.hasOwnProperty('gtin')"
                      :error-messages="errors['gtin']"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <th>MPN</th>
                  <td>
                    <v-text-field
                      v-model="fields.mpn"
                      type="text"
                      @blur="update('mpn')"
                      outlined
                      dense
                      hide-details
                      background-color="white"
                      :error="errors.hasOwnProperty('mpn')"
                      :error-messages="errors['mpn']"
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Shipping</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.detailDialog.openForm(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table>
              <tbody>
                <tr>
                  <th>Weight (kg)</th>
                  <td>
                    <v-text-field
                      label="Name *"
                      v-model="fields.name"
                      type="text"
                      outlined
                      dense
                      hide-details
                      background-color="white"
                      :error="errors.hasOwnProperty('name')"
                      :error-messages="errors['name']"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <th>Dimensions</th>
                  <td>
                    <v-row>
                      <v-col
                        ><v-text-field
                          label="Length"
                          v-model="fields.name"
                          type="text"
                          outlined
                          dense
                          hide-details
                          background-color="white"
                          :error="errors.hasOwnProperty('name')"
                          :error-messages="errors['name']"
                        ></v-text-field
                      ></v-col>
                      <v-col>
                        <v-text-field
                          label="Width"
                          v-model="fields.name"
                          type="text"
                          outlined
                          dense
                          hide-details
                          background-color="white"
                          :error="errors.hasOwnProperty('name')"
                          :error-messages="errors['name']"
                        ></v-text-field
                      ></v-col>
                      <v-col>
                        <v-text-field
                          label="Height"
                          v-model="fields.name"
                          type="text"
                          outlined
                          dense
                          hide-details
                          background-color="white"
                          :error="errors.hasOwnProperty('name')"
                          :error-messages="errors['name']"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card class="mb-4">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Meta Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.detailDialog.openForm(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Published on</th>
                  <td>{{ formatDateTime(product.created_at, true) }}</td>
                </tr>
                <tr>
                  <th>Last Edited</th>
                  <td>
                    <div v-if="product.user">
                      by {{ product.user.full_name }}<br />on
                      {{ formatDateTime(product.updated_at, true) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.detailDialog.openForm(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{{ product.name }}</td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>{{ formatPrice(product.price) }}</td>
                </tr>
                <tr>
                  <th>Sale Price</th>
                  <td></td>
                </tr>

                <tr>
                  <th>Category</th>
                  <td>{{ product.short_description }}</td>
                </tr>

                <tr>
                  <th>Stock</th>
                  <td>
                    {{ product.is_out_of_stock ? "Out of Stock" : "In Stock" }}
                  </td>
                </tr>

                <tr>
                  <th>Slug</th>
                  <td>{{ product.slug }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Gallery</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.imageDialog.openForm(product)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Image</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text class="pb-4">
              <v-row v-if="product.main_image">
                <v-col cols="12">
                  <v-card>
                    <v-img
                      height="200"
                      :src="product.main_image.asset_urls.url"
                      aspect-ratio="1"
                      cover
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                    >
                      <v-chip label class="ml-4 mb-4">Main Image</v-chip>
                    </v-img>
                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        x-small
                        depressed
                        color="red lighten-4 red--text"
                        @click="openDeleteImage(product.main_image, true)"
                      >
                        <v-icon x-small>mdi-archive</v-icon>
                      </v-btn>

                      <v-btn
                        x-small
                        depressed
                        color="blue lighten-4 blue--text"
                        @click="
                          $refs.imageDialog.openForm(
                            product,
                            product.main_image
                          )
                        "
                      >
                        <v-icon x-small>mdi-pencil</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>

              <draggable v-model="imagesOther" class="row">
                <template v-for="(image, i) in imagesOther">
                  <v-col v-bind:key="i" cols="12" sm="6" class="text-center">
                    <v-card>
                      <v-img
                        height="120"
                        :src="image.asset_urls.url"
                        aspect-ratio="1"
                        cover
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                      >
                      </v-img>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          x-small
                          depressed
                          color="red lighten-4 red--text"
                          @click="openDeleteImage(image)"
                        >
                          <v-icon x-small>mdi-archive</v-icon>
                        </v-btn>

                        <v-btn
                          x-small
                          depressed
                          color="blue lighten-4 blue--text"
                          class="mr-2"
                          @click="$refs.imageDialog.openForm(product, image)"
                        >
                          <v-icon x-small>mdi-pencil</v-icon>
                        </v-btn>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-if="!image.is_main"
                              x-small
                              depressed
                              color="orange lighten-4 orange--text"
                              @click="setMainImage(image)"
                            >
                              <v-icon x-small>mdi-image-check</v-icon>
                            </v-btn>
                          </template>
                          <span>Set Main Image</span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </template>
              </draggable>
            </v-card-text>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Brand</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.detailDialog.openForm(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-select
                :items="brands"
                item-text="name"
                item-value="id"
                dense
                outlined
                v-model="fields.brand_id"
                background-color="white"
              ></v-select>
            </v-card-text>
          </v-card>

          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Product Categories</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.detailDialog.openForm(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <treeselect
                :multiple="true"
                :options="categories"
                :flat="true"
                :isDefaultExpanded="true"
                :alwaysOpen="true"
                :default-expand-level="1"
                :show-count="false"
                :openOnClick="false"
                :closeOnSelect="false"
                v-model="fields.categories"
                class="no-absolute"
              />

              <v-btn
                :loading="loading"
                align="right"
                color="accent"
                @click="update('categories')"
                >Save</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <product-dialog ref="productDialog" />
    <image-dialog ref="imageDialog" />
    <related-dialog ref="relatedDialog" />
    <detail-dialog ref="detailDialog" />
    <attribute-dialog ref="attributeDialog" />
    <options-dialog ref="optionsDialog" />
    <assign-dialog ref="assignDialog" />

    <v-dialog v-model="deleteProductOption.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Product</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteProductOption.product.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteProductOption"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteProductOption.loading"
            @click="saveDeleteProductOption"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteImage.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Image</v-card-title>
        <v-card-text>Are you sure you wish to delete image?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteImage"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteImage.loading"
            @click="saveDeleteImage"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductDialog from "./components/ProductForm.vue";
import OptionsDialog from "./components/OptionsDialog.vue";
import ImageDialog from "./components/ImageDialog.vue";
import DetailDialog from "./components/DetailForm.vue";
import RelatedDialog from "./components/RelatedDialog.vue";
import AttributeDialog from "./components/AttributeDialog.vue";
import AssignDialog from "./components/AssignDialog.vue";
import Tiptap from "@/views/components/Tiptap.vue";

import Draggable from "vuedraggable";

export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: {
    ProductDialog,
    OptionsDialog,
    ImageDialog,
    RelatedDialog,
    DetailDialog,
    AttributeDialog,
    AssignDialog,
    Tiptap,
    Draggable,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Products",
          disabled: false,
          to: { name: "module-eow-products" },
          exact: true,
        },
      ],
      searchTerm: "",

      selectedAttributes: {},
      statusLoading: false,
      statuses: [
        { text: "Draft", value: "draft" },
        { text: "Published", value: "published" },
      ],
      fields: {
        description: null,
        isEditing: false,
        status: null,
        brand_id: null,
        categories: [],

        sku: null,
        gtin: null,
        mpn: null,
      },

      loading: false,
      loadingSale: false,
      errors: {},
      searchFilters: {
        menu: {
          open: false,
          closeOnClick: true,
          closeOnContentClick: false,
        },
      },
      errorMessage: "",
      deleteImage: {
        dialog: false,
        image: {},
        type: null,
        loading: false,
      },

      deleteProductOption: {
        dialog: false,
        product: {},
        loading: false,
      },
    };
  },

  mounted() {
    this.fields.status = this.product.status;
    this.fields.description = this.product.description;

    this.fields.sku = this.product.sku;
    this.fields.gtin = this.product.gtin;
    this.fields.mpn = this.product.mpn;

    this.fields.brand_id = this.product.brand;

    this.fields.categories = this.product.categories.map((c) => c.id);
  },

  computed: {
    product() {
      return this.$store.getters["eow/productsStore/get"];
    },

    categories() {
      let categories = this.$store.getters[
        "eow/productsStore/categories"
      ].filter((c) => {
        return c.is_parent === true;
      });

      categories = categories.map((category) => {
        let childExist = undefined;

        if (category.children.length > 0) {
          childExist = category.children.map((childCategory) => {
            return {
              id: childCategory.id,
              label: childCategory.name,
            };
          });
        }

        return {
          id: category.id,
          label: category.name,
          children: childExist,
        };
      });

      return categories;
    },

    brands() {
      return this.$store.getters["eow/productsStore/brands"];
    },

    imagesOther: {
      get() {
        return this.product.gallery;
      },
      set(items) {
        const displayOrder = items.map((p) => {
          return p.id;
        });
        const appId = this.$route.params.id;
        const productId = this.$route.params.productId;

        this.$store.commit("showHideLoader", true);
        this.$store
          .dispatch("eow/productsStore/updateGalleryOrder", {
            appId,
            displayOrder,
            productId,
          })
          .then(() => this.$store.commit("showHideLoader", false))
          .catch(() => this.$store.commit("showHideLoader", false));
      },
    },

    tableHeaders() {
      return [
        { text: "Customer", value: "customer" },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ];
    },
  },

  methods: {
    handleError(error) {
      this.errorMessage = error;
    },

    saveFormSale() {
      this.loadingSale = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        fields: this.fieldsSale,
      };

      // payload.fields.type = "message";

      this.$store
        .dispatch("eow/productsStore/saveProductSale", payload)
        .then(() => {
          this.resetFormSale();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loadingSale = false;
        });
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        fields: this.fieldsMessage,
      };

      // payload.fields.type = "message";

      this.$store
        .dispatch("eow/productsStore/saveProductEmail", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    openDeleteImage(image, type = null) {
      this.deleteImage.image = image;
      this.deleteImage.type = type;
      this.deleteImage.dialog = true;
    },

    resetDeleteImage() {
      this.deleteImage.dialog = false;
      this.deleteImage.image = {};
      this.deleteImage.loading = false;
    },

    saveDeleteImage() {
      this.deleteImage.loading = true;

      let payload = {
        appId: this.$route.params.id,
        imageId: this.deleteImage.image.id,
        isMain: this.deleteImage.type,
      };

      payload.productId = this.product.id;

      this.$store
        .dispatch("eow/productsStore/deleteProductImage", payload)
        .then(() => {
          this.resetDeleteImage();
        })
        .catch(() => {
          this.deleteImage.loading = false;
        });
    },

    openDeleteProductAttribute(product) {
      this.deleteProductOption.product = product;
      this.deleteProductOption.dialog = true;
    },

    resetDeleteProductOption() {
      this.deleteProductOption.dialog = false;
      this.deleteProductOption.product = {};
      this.deleteProductOption.loading = false;
    },

    saveDeleteProductOption() {
      this.deleteProductOption.loading = true;

      this.$store
        .dispatch("eow/productsStore/deleteProductOption", {
          appId: this.$route.params.id,
          productId: this.$route.params.productId,
          attributeId: this.deleteProductOption.product.pivot.id,
        })
        .then(() => {
          this.resetDeleteProductOption();
        })
        .catch(() => {
          this.deleteProductOption.loading = false;
        });
    },

    setMainImage(image) {
      this.isEditing = true;

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        fields: this.fields,
        isEditing: this.isEditing,
      };

      payload.fields.type = "main-image";
      payload.fields.imageId = image.id;

      payload.productId = this.product.id;

      this.$store
        .dispatch("eow/productsStore/saveProduct", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    statusChanged: function () {
      this.statusLoading = true;
      this.isEditing = true;

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        fields: this.fields,
        isEditing: this.isEditing,
      };

      payload.fields.type = "status";

      this.$store
        .dispatch("eow/productsStore/saveProduct", payload)
        .then(() => {
          this.statusLoading = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Status Updated Successfully!",
            color: "success",
          });
        })
        .catch(() => (this.statusLoading = false));
    },

    update(name) {
      this.loading = true;
      this.errors = {};
      this.isEditing = true;

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        fields: this.fields,
        isEditing: this.isEditing,
      };

      payload.fields.type = name;

      this.$store
        .dispatch("eow/productsStore/saveProduct", payload)
        .then(() => {
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: name + " Successfully Saved",
            color: "success",
          });
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;

          this.loading = false;
        });
    },

    resetForm: function () {
      this.loading = false;
      this.isEditing = true;
    },

    resetFormSale: function () {
      this.loadingSale = false;
      this.isEditing = true;
    },
  },
};
</script>
